<template>
  <BlueHeaderModal @close="$emit('close')">
    <template #header>
      <div class="dominant-zone" :class="dominantZone && dominantZone.toLowerCase()">{{ dominantZone }}</div>
      <label class="label">{{ title }}</label>
    </template>
    <template #body>
      <div class="chart">
        <div class="data">
          <div class="data-item">
            <span>{{ $t("workoutModal.time") }}</span>
            <p><svg-icon icon="time" />{{ duration }}m</p>
          </div>
          <div class="data-item">
            <span>{{ $t("workoutModal.stress") }}</span>
            <p><svg-icon icon="stress" />{{ stress }}</p>
          </div>
          <div class="data-item">
            <span>{{ $t("workoutModal.intensity") }}</span>
            <p><svg-icon icon="intensity" />{{ intensity }}</p>
          </div>
        </div>

        <canvas v-if="renderer === 'canvas'" ref="canvas" style="width: 100%;"></canvas>

        <ChartD3
          v-if="renderer === 'svg'"
          :segments="segments"
          :ftp="profileFacts.ftp"
          :workoutType="type"
          :thresholdHR="tdUser.thresholdHR"
          :isHRSegments="workoutTrainingType === 'heart_rate'"
          :durationMinutes="duration"
          :showSegmentDescriptionPoints="true"
          :isOutdoorsSegments="workoutTrainingType === 'outdoor'"
          :zones="tdUser.calculationsSettings.heartRateZones"
        />

        <div v-if="description" class="description">
          <div class="description-title">{{ $t("workoutModal.description") }}</div>
          <img v-if="isLoading" class="page-loader-icon" src="@/assets/images/loader.gif" alt="Loader" />
          <p v-else class="description-text" v-html="translatedDescription"></p>
        </div>
      </div>
      <section class="details-section details-section-white" style="display: none">
        <div class="container">
          <h3 class="title is-4">Time in Zones</h3>
          <div class="title is-7 coggan">based on Coggan Zones</div>

          <h3 class="title is-6">
            Dominant zone: <b>{{ dominantZone }}</b>
          </h3>

          <ZonesChart :zonesData="getTimeZoneData" />
        </div>
      </section>
    </template>
  </BlueHeaderModal>
</template>

<script>
import BlueHeaderModal from "../../../BlueHeaderModal"
import ChartD3 from "../../../ChartD3"
import ZonesChart from "../Chart/ZonesChart"
import { getTimeInZone } from "@trainerday/cycling-metrics"
import { mapActions, mapState } from "vuex"
import { MODELS_TRANSLATION } from "@/defaults"
import { SportType } from "@trainerday/ergdb-types"

export default {
  name: "WorkoutModal",
  components: {
    BlueHeaderModal,
    ChartD3,
    ZonesChart
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    workoutTrainingType: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: SportType.BIKE
    },
    segments: {
      type: Array,
      default: () => []
    },
    duration: {
      type: Number,
      default: 0
    },
    stress: {
      type: Number,
      default: 0
    },
    intensity: {
      type: Number,
      default: 0
    },
    dominantZone: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      renderer: "svg",
      translation: "",
      isLoading: false
    }
  },
  computed: {
    ...mapState(["profileFacts", "tdUser"]),
    translatedDescription() {
      return this.translation || this.description
    },
    getTimeZoneData() {
      const toz = getTimeInZone(this.segments)
      const tozInMinutes = {}

      Object.keys(toz).forEach(key => {
        tozInMinutes[key] = Number((toz[key] / 60).toFixed(2))
      })

      const timeInZones = {
        z1: tozInMinutes.z1,
        z2: tozInMinutes.z2,
        z3: tozInMinutes.z3,
        z4: tozInMinutes.z4,
        z5: tozInMinutes.z5,
        z6: tozInMinutes.z6
      }

      return timeInZones
    }
  },
  methods: {
    ...mapActions(["getTranslation"])
  },
  async mounted() {
    this.isLoading = true
    if (!this.description) {
      this.translation = ""
    } else {
      const response = await this.getTranslation({
        text: this.description,
        model: MODELS_TRANSLATION.WORKOUT
      })

      if (response) {
        this.translation = response
      }
    }

    this.isLoading = false
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

.chart {
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
}

.data {
  display: flex;
  gap: 50px;
  margin-bottom: 30px;

  &-item {
    display: flex;
    flex-direction: column;
    font-size: 16px;

    span {
      color: #acacac;
    }
  }
  svg {
    margin-right: 5px;
  }
}

::v-deep {
  .blue-header-modal-container {
    margin-top: 60px;
  }
  .blue-header-modal-header {
    padding: 33px 50px 10px 30px;

    .field {
      width: 80%;
    }
    @include media("<phone") {
      padding: 20px;
    }
  }
  .blue-header-modal-footer {
    display: none;
  }
  .field {
    display: flex;
    align-items: center;
  }
}

.description {
  margin-bottom: 40px;

  &-title {
    font-weight: 600;
    font-size: 1.2em;
    margin-bottom: 5px;
  }
}

.details-section {
  //padding-top: 60px;
  //padding-bottom: 60px;

  .title {
    text-align: center;
    font-weight: 500;
    color: $gray2;
    margin-bottom: 10px;

    b {
      text-transform: capitalize;
    }
  }

  @include media("<desktop") {
    padding-left: 10px;
    padding-right: 10px;
  }

  @include media("<tablet") {
    padding-left: 0;
    padding-right: 0;
  }

  &-gray {
    background-color: $gray1;
  }

  &-white {
    background-color: white;
  }

  &-chart {
  }

  &-info {
    display: flex;
    margin: 40px -11px 60px;

    @include media("<tablet") {
      margin: 40px -11px 30px;
    }

    @include media("<phone") {
      margin: 30px -6px 20px;
    }

    &-item {
      flex: 1;
      padding: 0 11px;

      @include media("<tablet") {
        flex-basis: 100%;
        margin-bottom: 10px;
      }

      @include media("<phone") {
        padding: 0 6px;
      }
    }
  }

  &-description {
    white-space: pre-wrap;
  }
}
</style>
