<template>
  <div class="calendar-workout-item">
    <div v-if="sick" class="workout-sick">
      <span v-for="index in 8" :key="index" class="workout-sick-text"
        >{{ $t("planDuration.daysOffText") }} {{ $t("planDuration.daysOffText") }}
      </span>
    </div>
    <span class="weekday">{{ day }}</span>
    <p
      v-if="weekIndex === 0 && getIndoorOutdoorType && workoutIndex === 0"
      class="in-out-door"
      @click="showIndoorOutdoorModal = true"
    >
      {{ getIndoorOutdoorType }}
    </p>
    <div class="chart-info" @click.capture="showChartModal = true">
      <div class="details" :class="dominantZone.toLowerCase()">
        <div class="is-uppercase text-truncate details-title" v-html="title"></div>
        <div class="details-bottom">
          <div class="details-date" v-if="isExtended">
            <svg-icon icon="calendar-outlined"></svg-icon>
            {{ day }}
          </div>
          <span class="details-divider" v-if="isExtended">•</span>
          <div class="details-stats">
            <WorkoutStat v-if="view === 'duration'" :value="getTime(Math.round(durationMinutes))" icon="time" />
            <div class="is-uppercase text-truncate font-bold">{{ dominantZone }}</div>
            <WorkoutStat v-if="view === 'stress'" :value="tss" icon="stress" />
            <WorkoutStat v-if="view === 'intensity'" :value="intensity" icon="tachometer-alt" />
          </div>
        </div>
      </div>
      <div class="chart">
        <canvas ref="canvas"></canvas>
      </div>
    </div>
    <WorkoutModal
      v-if="showChartModal"
      @close="showChartModal = false"
      :title="title"
      :type="type"
      :segments="segmentsToRender"
      :workoutTrainingType="workoutTrainingType"
      :duration="durationMinutes"
      :stress="tss"
      :intensity="intensity"
      :dominantZone="dominantZone"
      :description="description"
    />
    <IndoorOutdoorModal
      v-if="showIndoorOutdoorModal"
      @close="showIndoorOutdoorModal = false"
      :description="getIndoorOutdoorDescription"
      :type="getIndoorOutdoorType"
      :title="type"
    />
  </div>
</template>
<script>
import WorkoutStat from "../WorkoutStat"
import WorkoutModal from "@/components/plan/Calendar/Modals/WorkoutModal"
import IndoorOutdoorModal from "@/components/plan/Calendar/Modals/IndoorOutdoorModal"
import canvasChart from "@/mixins/canvasChart"
import Converter, { PowerMode } from "@trainerday/cycling-converter"
import { mapState } from "vuex"

export default {
  name: "CalendarItem",
  components: { IndoorOutdoorModal, WorkoutStat, WorkoutModal },
  mixins: [canvasChart],
  props: {
    workoutIndex: { type: Number },
    title: { type: String },
    dominantZone: { type: String, default: "" },
    type: { type: String },
    workoutTrainingType: { type: String },
    segments: { type: Array },
    view: { type: String },
    durationMinutes: { type: Number },
    tss: { type: Number },
    intensity: { type: Number },
    description: { type: String },
    day: {},
    isExtended: {
      type: Boolean,
      default: false
    },
    weekIndex: { type: Number },
    workoutIndoorOutdoorTypes: { type: Array },
    sick: {
      type: Boolean
    }
  },
  data() {
    return {
      renderer: "svg",
      showChartModal: false,
      showIndoorOutdoorModal: false,
      watermarkCount: 3,
      segmentsToRender: this.segments
    }
  },
  computed: {
    ...mapState(["tdUser"]),
    getIndoorOutdoorType() {
      return this.workoutIndoorOutdoorTypes.find(type => type.name === this.type)?.type
    },
    getIndoorOutdoorDescription() {
      return this.workoutIndoorOutdoorTypes.find(type => type.name === this.type)?.desc
    },
    isWorkoutHRUnits() {
      return this.workoutTrainingType === "heart_rate"
    },
    isOutdoorWorkout() {
      return this.workoutTrainingType === "outdoor"
    },
  },
  watch: {
    segments() {
      this.renderChart()
    },
    durationMinutes() {
      this.renderChart()
    },
    isWorkoutHRUnits() {
      this.renderChart()
    },
    isOutdoorWorkout() {
      this.renderChart()
    }
  },
  methods: {
    getTime(time) {
      let hours = 0
      let mins = 0
      if (time < 60) {
        return `${time}m`
      } else {
        hours = Math.trunc(time / 60)
        mins = time % 60
        return `${hours}h ${mins}m`
      }
    },
    renderChart() {
      let segmentColorScheme = "default"

      const converter = new Converter()
      converter.setStandardArray(this.segments)
      if (this.isOutdoorWorkout) {
        this.segmentsToRender = converter.getOutdoorSegmentsArray()
        segmentColorScheme = "outdoor"
      } else if (this.isWorkoutHRUnits) {
        converter.setHeartRateZones(this.tdUser.calculationsSettings.heartRateZones)
        converter.setPowerMode(PowerMode.HEART_RATE_PERCENT)
        this.segmentsToRender = converter.getHRSegmentsArray()
        segmentColorScheme = "hr"
      } else {
        this.segmentsToRender = this.segments
      }
      const payload = {
        chartType: "mini",
        showYLines: false,
        width: this.$el.clientWidth,
        segmentColorScheme,
        segments: this.segmentsToRender,
        maxValueY: 180
      }
      this.renderCanvasChart(payload)
    }
  },
  mounted() {
    if (this.segments && this.tdUser.calculationsSettings) {
      this.renderChart()
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";

.calendar-workout-item {
  width: 100%;
  cursor: pointer;
  border-radius: 6px;
  //overflow: hidden;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.details {
  padding: 4px 8px;
  border-radius: 5px;
  line-height: 15px;
  font-size: 10px;

  @include media("<desktop") {
    padding: 8px;
    font-size: 9px;
    line-height: 13px;
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  span {
    padding-right: 2px;
  }

  &-title {
    color: $plans-text-color-primary;
    display: block;

    @include media("<desktop") {
      margin-bottom: 6px;
    }
  }

  &-dominant {
    font-weight: bold;
    text-align: left;
  }

  &-stats {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }

  &-date {
    font-weight: 500;
    display: inline-flex;
    gap: 5px;
    align-items: center;
    margin-right: 12px;
  }

  &-divider {
    margin-right: 12px;
  }
}

.chart {
  width: 100%;
  overflow: hidden;
  border-radius: 0 0 5px 5px;

  @include media("<desktop") {
  }
}

.in-out-door {
  text-transform: uppercase;
  font-size: 10px;
  color: $blue;
  text-decoration: underline;
  margin-bottom: 8px;
}
.weekday {
  display: none;
  text-transform: uppercase;
  @include media("<=tablet") {
    display: initial;
  }
}
.workout-sick {
  display: grid;
  grid-template-columns: repeat(10, 20%);
  justify-items: start;
  margin-left: -35px;
  position: absolute;
  font-size: 20px;
  &-text {
    display: inline-block;
    writing-mode: vertical-lr;
    transform: rotate(225deg);
    white-space: nowrap;
    letter-spacing: 1.5px;
  }
}
.chart {
  height: 53px;
}
</style>
