<template>
  <div class="calendar-workouts flex1">
    <div class="days-names mb-16" ref="daysNames">
      <template v-for="(isHasWorkout, dayIndex) in weekDays">
        <div
          :key="dayIndex"
          class="days-name"
          :class="{ 'has-not-workout': !isHasWorkout, 'has-workout': isHasWorkout }"
        >
          {{ getDayName(isHasWorkout, dayIndex) }}
        </div>
      </template>
    </div>

    <div class="weeks" v-for="(week, weekIndex) in only4weeks" :key="weekIndex">
      <div class="weeks-title">
        <div class="weeks-title-main">
          <div class="weeks-title-main-block">
            <div
              v-if="isEventBasedPlan || !isSingleBlockOn"
              class="block-type"
              :class="blockTypeColor(blockTypes[weekIndex + view.activeWeek - 1])"
            >
              {{ $t(`common.blockTypes.${blockTypes[weekIndex + view.activeWeek - 1]}`) }} -&nbsp;
            </div>
            <span class="week-number">{{ $t("common.week") }} {{ weekIndex + view.activeWeek }}</span>
          </div>

          <span class="week-date nowrap">{{ getWeekDate(weekIndex + view.activeWeek) }}</span>
        </div>
        <div class="statistic">
          <div class="nowrap">
            <svg-icon icon="time"></svg-icon>
            {{ $t("calendar.total") }}: {{ getTime(totalDuration(week)) }}
          </div>
          <div class="nowrap">
            <svg-icon icon="stress"></svg-icon>
            {{ $t("calendar.stress") }}:
            {{ view.workoutStatsLoading ? `${$t("common.loading")}...` : totalStress(week) }}
          </div>
        </div>
      </div>
      <div v-if="isWeekSick(week, weekIndex) && splitLongRides && !inQuestions" class="days-off">
        <span v-for="index in watermarkCountInSplitWeek" :key="index" class="days-off-week-text"
          >{{ $t("planDuration.daysOffText") }} {{ $t("planDuration.daysOffText") }}
          {{ $t("planDuration.daysOffText") }}
        </span>
      </div>
      <div v-if="isWeekSick(week, weekIndex) && !splitLongRides && !inQuestions" class="days-off">
        <span v-for="index in watermarkCount" :key="index" class="days-off-week-text"
          >{{ $t("planDuration.daysOffText") }} {{ $t("planDuration.daysOffText") }}
        </span>
      </div>
      <div
        :class="{
          'weeks-items': !isWeekSick(week, weekIndex) || inQuestions,
          'weeks-sick-items': isWeekSick(week, weekIndex) && !inQuestions
        }"
      >
        <template v-for="(day, dayIndex) in week">
          <div
            v-if="!day.length"
            class="weeks-workout-day"
            :key="dayIndex"
            :class="{
              'has-not-workout': !weekDays[dayIndex],
              'has-workout': weekDays[dayIndex]
            }"
          >
            <NoTrainingDay :day="getDay(dayIndex)" />
          </div>
          <div
            v-else-if="day.length && !day[0].type && day[0].workoutSick"
            class="weeks-workout-day"
            :key="dayIndex"
            :class="{
              'has-not-workout': !weekDays[dayIndex],
              'has-workout': weekDays[dayIndex]
            }"
          >
            <NoTrainingDaySick :day="getDay(dayIndex)" />
          </div>
          <div
            v-if="day.length && day[0].type"
            class="weeks-workout-day has-workout"
            :key="dayIndex"
            :class="{
              'event-day': day[0].type === 'EVENT',
              'day-in-past': isDayInPast(dayIndex, weekIndex),
              'day-sick': day[0].workoutSick && !isWeekSick(week)
            }"
          >
            <div v-if="day[0].workoutSick && !isWeekSick(week, weekIndex)" class="days-off">
              <span v-for="index in 8" :key="index" class="days-off-text"
                >{{ $t("planDuration.daysOffText") }} {{ $t("planDuration.daysOffText") }}
              </span>
            </div>
            <template v-for="(workout, workoutIndex) in day">
              <CalendarItem
                v-if="workout.hasStructure && !loading && isUserFetched && !workout.isEventDate"
                :key="workoutIndex"
                :workoutIndex="workoutIndex"
                :title="workout.title"
                :durationMinutes="workout.durationMinutes"
                :tss="workout.tss"
                :intensity="workout.intensity"
                :view="'duration'"
                :dominant-zone="workout.dominantZone"
                :segments="workout.segments"
                :slug="workout.slug"
                :link="true"
                :isExtended="isMobile"
                :day="getDay(dayIndex)"
                class="workout inline-block-middle has-tooltip-multiline has-tooltip-arrow"
                :class="{ event: workout.type === 'EVENT' }"
                :description="workout.description"
                :workoutIndoorOutdoorTypes="values.workoutIndoorOutdoorTypes"
                :weekIndex="weekIndex"
                :type="workout.type"
                :workoutTrainingType="workout.workoutTrainingType"
              />
              <EventItem
                v-if="workout.isEventDate"
                :eventData="workout"
                :key="workoutIndex"
                class="workout inline-block-middle has-tooltip-multiline has-tooltip-arrow"
              />
              <div class="workout inline-block-middle" v-if="!workout.type" :key="workoutIndex">
                <NoTrainingDay :day="getDay(dayIndex)" />
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import CalendarItem from "@/components/plan/Calendar/Items/CalendarItem"
import NoTrainingDay from "@/components/plan/Calendar/Items/NoTrainingDay"
import NoTrainingDaySick from "@/components/plan/Calendar/Items/NoTrainingDaySick"
import EventItem from "@/components/plan/Calendar/Items/EventItem"
import { mapGetters, mapState, mapActions } from "vuex"
import moment from "moment"
import "moment/locale/ru"
import "moment/locale/es"
import "moment/locale/en-gb"
import { i18n } from "@/main"

export default {
  name: "Calendar",
  components: { CalendarItem, NoTrainingDay, EventItem, NoTrainingDaySick },
  data() {
    return {
      watermarkCount: 28,
      watermarkCountInSplitWeek: 60,
      isUserFetched: false
    }
  },
  props: {
    inQuestions: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    only4weeks: {
      type: Array,
      default: () => []
    },
    blockTypes: {
      type: Array,
      default: () => []
    },
    weekDays: {
      type: Array,
      default: () => [true, true, true, true, true, true, true]
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState("plan", ["values", "view"]),
    ...mapState(["tdUser"]),
    ...mapGetters("plan", ["getPlanConfigValue", "calculatedBlocks"]),
    days() {
      return this.values.workoutDaysValues.map(v => v.name)
    },
    splitLongRides() {
      return this.calculatedBlocks[0].splitLongRides
    },
    isSingleBlockOn() {
      return this.getPlanConfigValue("isSingleBlockOn")
    },
    isEventBasedPlan() {
      return this.getPlanConfigValue("isEventBasedPlan")
    },
  },
  methods: {
    ...mapActions(["fetchTDUser"]),
    getDay(dayIndex) {
      return this.days[dayIndex]
    },
    getDayName(isHasWorkout, dayIndex) {
      return isHasWorkout ? this.days[dayIndex] : this.days[dayIndex].substr(0, 3)
    },
    totalDuration(week) {
      let total = 0
      week.forEach(day => {
        const totalDayWorkoutMinutes = day.reduce((acc, dayWorkout) => {
          if (dayWorkout && dayWorkout.durationMinutes) {
            return acc + dayWorkout.durationMinutes
          }
          return acc
        }, 0)
        total += totalDayWorkoutMinutes
      })
      return Math.round(total)
    },
    totalStress(week) {
      let total = 0

      week.forEach(day => {
        const totalDayWorkoutStress = day.reduce((acc, dayWorkout) => {
          if (dayWorkout && dayWorkout.durationMinutes) {
            return acc + dayWorkout.tss
          }
          return acc
        }, 0)
        total += totalDayWorkoutStress
      })
      return total
    },
    getTime(time) {
      let hours = 0
      let minutes = 0
      if (time < 60) {
        return `${time}m`
      } else {
        hours = Math.trunc(time / 60)
        minutes = time % 60
        if (minutes < 10) return `${hours}:0${minutes}`
        else return `${hours}:${minutes}`
      }
    },
    blockTypeColor(block) {
      return block === "base"
        ? "base-block"
        : block === "build"
        ? "build-block"
        : block === "peak"
        ? "peak-block"
        : block === "event"
        ? "event-block"
        : "race-block"
    },
    getWeekDate(i) {
      const allWeeksDates = this.calculatedBlocks.map(block => block.blockDatesByWeek).flat()
      const date = moment(allWeeksDates[i - 1][0])
      return this.$t("calendar.starts") + " " + date.format("MMM Do, YYYY")
    },
    isDayInPast(dayIndex, weekIndex) {
      const startDate = this.getPlanConfigValue("startDate")
      const number = (this.view.activeWeek - 1) * 7 + weekIndex * 7 + dayIndex
      return moment(startDate)
        .add(number, "d")
        .isBefore(moment().subtract(1, "d"))
    },
    isWeekSick(week) {
      const sickDaysInWeek = week
        .map(day => {
          return day.filter((workout, index) => index === 0 && workout.workoutSick && workout.workoutType)
        })
        .flat()
      return sickDaysInWeek.length > 2
    }
  },
  async beforeMount() {
    await this.fetchTDUser()
    this.isUserFetched = true
  },
  async mounted() {
    const { locale } = i18n
    moment.locale(locale)
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";

.calendar-workouts {
  @include media("<desktop") {
    min-width: 1412px;
    width: 1412px;
  }
  @include media("<tablet") {
    min-width: auto;
    width: 100%;
  }
}

.days-names {
  display: flex;
  background-color: $gray9;
  text-align: center;
  gap: 2px;
  @include media("<tablet") {
    display: none;
  }

  > div {
    background-color: white;
    padding: 16px 32px;
  }
}

.days-name {
  width: 100%;
  color: $dark-blue;
  font-weight: 600;
}

.days-name.has-not-workout {
  color: $gray15;
}

.weeks {
  margin-top: 14px;
  background-color: $gray9;
  position: relative;
  overflow: hidden;
  &-title {
    font-size: 14px;
    font-weight: 500;
    padding: 4px 16px 4px 8px;
    margin-bottom: 1px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    gap: 50px;
    align-items: center;
    border-bottom: 1px solid $gray9;
    @include media("<tablet") {
      align-items: flex-start;
      gap: 10px;
    }

    &-main {
      display: flex;
      align-items: center;
      @include media("<tablet") {
        flex-direction: column;
        align-items: flex-start;
      }
      &-block {
        display: flex;
        flex-direction: row;
      }
    }

    .statistic {
      display: flex;
      gap: 40px;

      div {
        display: flex;
        align-items: center;
        gap: 5px;
        @include media("<tablet") {
          justify-content: flex-end;
        }
      }
      @include media("<tablet") {
        gap: 12px;
      }
      @include media("<phone") {
        font-size: 12px;
      }
      @include media("<=375px") {
        font-size: 10px;
      }
    }
  }

  &-items {
    display: flex;
    gap: 2px;
    @include media("<tablet") {
      flex-direction: column;
      width: 100%;
    }

    > * {
      background-color: white;
    }
  }
  &-sick-items {
    display: flex;
    gap: 2px;
    opacity: 0.2;
    //@include media("<desktop") {
    //  display: grid;
    //  grid-template-columns: repeat(2, 1fr);
    //}
    //
    //@include media("<tablet") {
    //  grid-template-columns: repeat(1, 1fr);
    //}

    > * {
      background-color: white;
    }
  }
}
.days-off {
  display: grid;
  grid-template-columns: repeat(28, 40px);
  position: absolute;
  width: 100%;
  height: 100%;
  justify-items: start;
  overflow: hidden;
  grid-gap: 10px;

  &-text {
    display: inline-block;
    writing-mode: vertical-lr;
    transform: rotate(225deg);
    white-space: nowrap;
    font-size: 24px;
    margin-top: -90px;
    margin-left: -170px;
    letter-spacing: 2.5px;
    z-index: 2;
  }
  &-week-text {
    display: inline-block;
    writing-mode: vertical-lr;
    transform: rotate(225deg);
    white-space: nowrap;
    font-size: 24px;
    margin-top: -90px;
    margin-left: -170px;
    opacity: 0.2;
    letter-spacing: 2.5px;
    z-index: 2;
  }
}
.has-not-workout {
  width: 116px;
  min-width: 116px;
  overflow: hidden;
  padding: 8px;
  @include media("<tablet") {
    width: 100%;
  }
}

.has-workout {
  flex: 1 1 0;
  padding: 8px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @include media("<desktop-lg") {
    min-width: 200px;
  }
  @include media("<tablet") {
    min-width: auto;
    width: 100%;
  }
}

.block-type {
  padding: 0 5px;
  margin-right: -5px;
  text-transform: uppercase;
  font-weight: bold;
  @include media("<tablet") {
    min-width: auto;
    width: 100%;
    padding: 0;
    margin-right: 0;
  }
}

.base-block {
  color: $base;
}
.build-block {
  color: $build;
}
.peak-block {
  color: $peak;
}
.event-block {
  color: $event;
}

.week {
  &-number {
    text-transform: capitalize;
    font-weight: bold;
    white-space: nowrap;
  }

  &-date {
    color: $gray7;
    margin-left: 20px;
    @include media("<tablet") {
      margin-left: 0;
      font-size: 12px;
    }
    @include media("<=375px") {
      font-size: 10px;
    }
  }
}

.event-day {
  border: 2px solid #ffc700;
  padding: 0 5px;
  justify-content: center;
}

.day-in-past {
  //opacity: 0.5;
  position: relative;
  &:before {
    left: 0;
    top: 0;
    z-index: 1;
    background-color: $white;
    pointer-events: none;
    content: "";
    position: absolute;
    opacity: 0.5;
    height: 100%;
    width: 100%;
  }
}
.day-sick {
  position: relative;
  overflow: hidden;
  opacity: 0.2;
}
</style>
